import React, { Fragment, useEffect, useState } from "react";
import Previous from "../assets/images/previous.svg";
// import Wallet from "../assets/images/wallet.svg";
// import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CopyIcon from "../assets/images/copy-icon.svg";
import Close from "../assets/images/close.svg";
// import PremiumLogo from "../assets/images/premium.png";
import PremiumLogoBlack from "../assets/images/GoldPremium.png";
import placeHolder from "../assets/images/vi-user-placeholder.png";

import {
  // getAllLeaderboardsList,
  // getContestEventByCode,
  getContestRate,
  getContestDetails,
  getContestPlayerList,
  contestRate,
  getContestRules,
  getUserDetails
} from "../apis";
import {
  // dateFormat,
  getProfiledateFormat,
  formatAMPM,
  goback,
} from "../component/common";
import Friendship from "../assets/images/friendship.svg";
import R2 from "../assets/images/r-2.svg";
import R3 from "../assets/images/r-3.svg";
import R4 from "../assets/images/r-4.svg";
import R5 from "../assets/images/r-5.svg";
import { useRanger } from "react-ranger";
import Gamer from "../assets/images/gamer.svg";
import Gun2 from "../assets/images/gun-2.svg";
import Podium from "../assets/images/podium.svg";
import R1 from "../assets/images/r-1.svg";
// import Picon1 from "../assets/images/p_icon_1.png";
// import TrophyBlue from "../assets/images/ic_trophy_blue.png";
import TrophyYellow from "../assets/images/ic_trophy_yellow.png";
import url from "../constants/url";
import { constants } from "../constants/constants";
import ReactHtmlParser from "react-html-parser";
import { RWebShare } from "react-web-share";
import Loader from "../component/loader";

import Advertisement from "../component/advertisement";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie'
import { useNavigate } from "react-router-dom";

function ContestDetails(props) {
  const [loader, setLoader] = useState(false);
  const [allData, setAllData] = useState(null);
  // const [allRound, setAllRound] = useState([true, false, false]);
  const [values, setValues] = React.useState([10]);
  const [progressWidth, setProgressWidth] = useState(0);
  const [captainData, setCaptainData] = useState([]);
  // const [captainLevelData, setCaptainLevelData] = useState({});
  const [allPlayersData, setAllPlayersData] = useState([]);
  const [rate, setRate] = React.useState(0);
  const [showWinners, setShowWinners] = useState(false);
  const [allWinners, setAllWinners] = useState([]);
  // const [limitData, setlimitData] = useState(0);
  const [page, setPage] = useState(0);
  const [rules, setRules] = useState('');
  const [isLastPage, setIsLastPage] = useState(false);
  const [premiumUser, setPremiumUser] = useState(
    JSON.parse(localStorage.getItem("premiumUser"))
  );
  const { t } = useTranslation()
  const currentLang = Cookies.get('i18next') || 'EN'
  const navigate = useNavigate()

  // const { getTrackProps, handles } = useRanger({
  //   min: 0,
  //   max: 50,
  //   stepSize: 10,
  //   values,
  //   onChange: setValues,
  // });

  useEffect(() => {
    fetchData();
    fetchRating();
  }, []);

  const fetchRating = async () => {
    var gameid =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];

    const res = await getContestRate({
      contest: gameid,
    });
    // console.log(res);
    if (res?.data) {
      setRate(res?.data?.rate);
    }
    getCaptainData()
  };
  const getCaptainData = async () => {
    var contestId = window.location.href.split("/")[window.location.href.split("/").length - 1];
    var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
    const res = await getUserDetails(contestId, { "search": { "user": user_data?.item?.user_id } });
    // console.log(res);
    if (res?.captainData) {
      setCaptainData(res?.captainData)
      // setRate(res?.data?.rate);
    }
  };

  const fetchData = async () => {
    setLoader(true);
    if (localStorage.getItem("gamerjiToken")) {
      let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
      var count = allpage.findIndex((d) => d === window.location.pathname);
      count === -1 && allpage.push(window.location.pathname);
      localStorage.setItem("currentPage", JSON.stringify(allpage));

      // var gameid = JSON.parse(localStorage.getItem("joinTounament"))?.shortCode;
      var contestid =
        window.location.href.split("/")[
        window.location.href.split("/").length - 1
        ];
      let response = await getContestDetails(contestid);
      // console.log('getContestDetails: ', response)
      if (response) {
        var per = (100 * response?.data?.totalJoinedPlayers) / response?.data?.totalPlayers + "%";
        setProgressWidth(per);
        // console.log(response?.data)
        setAllData(response?.data);

        setLoader(false);
      }
      // getLoadMore();
    } else {
      window.location.href = "/sign-in";
    }
  };

  const getLoadMore = async () => {
    setLoader(true);

    var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
    var contestid =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];

    var payloadPlayer = {
      search: {
        user: user_data?.item?.user_id,
      },
      pagination: {
        pageNo: page + 1,
        recordPerPage: 10,
        sortBy: "rank",
        sortDirection: "asc",
      },
    };

    // setlimitData(limitData + 10)

    let playerResponse = await getContestPlayerList(contestid, payloadPlayer);
    // console.log("playerResponse", playerResponse);
    if (playerResponse && playerResponse?.data?.length < 10) {
      setIsLastPage(true);
    }
    if (playerResponse && playerResponse?.data) {
      if (page === 0) setCaptainData(playerResponse?.captainData);
      // setCaptainLevelData(playerResponse?.CaptainLevelData);
      setAllPlayersData([...allPlayersData, ...playerResponse?.data]);

      // setAllPlayersData(playerResponse?.data);
    }

    setLoader(false);
    setPage(page + 1);
  };

  const onChangeRate = async (rate) => {
    setRate(rate);
    var gameid =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];

    const data = { rate: rate, contest: gameid };
    let res = await contestRate(data);
    if (res) {
      // console.log(res);
    }
  };

  const getWinnerClick = (item) => {
    setShowWinners(true);
    setAllWinners(item);
  };

  const getRules = async () => {
    setLoader(true);
    var contestid = window.location.href.split("/")[window.location.href.split("/").length - 1];
    let response = await getContestRules(contestid);
    // console.log('getContestDetails: ', response)
    if (response) {
      setRules(response?.data?.rules);
      setLoader(false);
    } else {
      setLoader(false);
    }
  }

  return (
    <div className="inner-wrap">
      <ToastContainer />
      <div className="header">
        <div className="back-btn">
          <a onClick={() => navigate(-1)}>
            <img src={Previous} alt="" />
          </a>
        </div>
        <h2>{t('contest_details.contest_details')}</h2>
        <div className="wallet-btn">
        </div>
      </div>
      {loader && <Loader />}

      <div
        className="body mCustomScrollbar _mCS_1 bottom-btn-body"
        id={`${showWinners === true ? "popup_show" : ""}`}
        style={{ paddingTop: "10px" }}
      >
        <div className="all_sub_screens_div_related_to_ads">
          <div className="list-group main-tab" id="list-tab" role="tablist">
            <a
              className="list-group-item list-group-item-action active"
              id="list-home-list"
              data-toggle="list"
              href="#list-1"
            >
              {t('crm.details')}
            </a>
            <a
              className="list-group-item list-group-item-action"
              id="list-profile-list"
              data-toggle="list"
              href="#list-2"
              onClick={() => {
                getLoadMore()
              }}
            >
              {t('contest_details.players')}
            </a>
            <a
              className="list-group-item list-group-item-action"
              id="list-profile-list"
              data-toggle="list"
              href="#list-3"
              onClick={() => {
                getRules()
              }}
            >
              {t('contest_details.rules')}
            </a>
          </div>
          <div className="tab-content" id="nav-tabContent">
            {/* <--------------------- Contest Details tab --------------------> */}
            <div className="tab-pane active" id="list-1">
              <div className="contest-list contest-tournament-list game_tounament">
                <div className="box">
                  <h3 style={{ fontSize: "16px", fontWeight: "800" }}>
                    {/* <marquee> */}
                    {allData?.title}
                    {/* </marquee> */}
                    <span className="pointer">{t('contest_details.id')}: {allData?.shortCode}</span>
                  </h3>
                  <div
                    className="row tournament_contest_row"
                    style={{ marginBottom: "-37px" }}
                  >
                    <div className="col-12">
                      <div className="inner_row contest">
                        <div className="inner_item">
                          {t('contest_details.date')} <b>{getProfiledateFormat(allData?.date)}</b>
                        </div>
                        <div className="inner_item">
                          {t('contest_details.time')} <b>{formatAMPM(new Date(allData?.time))}</b>
                        </div>
                        {allData?.titles?.slice(0, 2)?.map((item, index) => {
                          if (item?.name !== "-") {
                            return (
                              <div className="inner_item" key={index}>
                                {item?.name} <b>{item?.value}</b>
                              </div>
                            );
                          }
                        })}
                      </div>
                      <div className="inner_row bg-dark">
                        <div className="inner_item">
                          {t('contest_details.prize_pool')} <br />{" "}
                          <b>
                            {allData?.contestFor[0]?.outCurrencyInfo?.code ==
                              "INR" ||
                              allData?.contestFor[0]?.outCurrencyInfo?.code ==
                              "inr" ? (
                              "₹"
                            ) : (
                              <img
                                src={
                                  url.imageUrl +
                                  allData?.contestFor[0]?.outCurrencyInfo?.img
                                    ?.default
                                }
                                className="coin_img"
                              />
                            )}{" "}
                            {allData?.prizePool}
                          </b>
                        </div>
                        <div className="inner_item">
                          {allData?.winningModel === "perRank" ? t('contest.winners') : t('contest.per_kill')}{" "}
                          {allData?.winningModel === "perRank" ? (
                            <b
                              className="pointer"
                              onClick={() => getWinnerClick(allData)}
                            >
                              {" "}
                              {allData?.totalWinners}{" "}
                              <i className="fas fa-chevron-down"></i>
                            </b>
                          ) : (
                            <b className="">
                              {allData?.contestFor[0]?.outCurrencyInfo?.code ==
                                "INR" ? (
                                "₹"
                              ) : (
                                <img
                                  src={
                                    url.imageUrl +
                                    allData?.contestFor[0]?.outCurrencyInfo?.img
                                      ?.default
                                  }
                                  alt=""
                                  className="coin_img"
                                />
                              )}{" "}
                              {allData?.killPoints}{" "}
                            </b>
                          )}
                        </div>
                        <div className="inner_item">
                          {t('contest.join_using')} <br />{" "}
                          {allData?.entryFee > 0 ? (
                            <b>
                              <img
                                src={
                                  url.imageUrl +
                                  allData?.contestFor[0]?.inCurrencyInfo?.img
                                    ?.default
                                }
                                className="coin_img"
                              />{" "}
                              {allData?.entryFee}
                            </b>
                          ) : (
                            <b>{t('contest_details.free')}</b>
                          )}
                        </div>
                      </div>
                      <div className="avalible_wrap">
                        <div className="inner">
                          <span
                            style={{
                              width: progressWidth,
                            }}
                          ></span>
                        </div>
                        <p className="d-flex justify-content-between text-light">
                          <span className="">
                            {t('contest_details.players_remaining', {
                              players: allData?.totalPlayers -
                                allData?.totalJoinedPlayers
                            })}
                          </span>
                          <span className="">
                            {t('contest_details.players_joined', { players: allData?.totalJoinedPlayers })}
                          </span>
                        </p>
                      </div>
                      <div className="contest_foot contest_single p-2">
                        <div className="inner pl-4">
                          <span>{t('contest_details.room_id')}</span>
                          <b style={{ fontSize: "12px" }}>
                            {allData?.roomId === "" ? "-" :
                              allData?.roomId?.length > 25 ? (
                                <marquee>{allData?.roomId}</marquee>
                              ) : (
                                allData?.roomId
                              )}{" "}
                            {allData?.roomId != "" && (
                              <img
                                src={CopyIcon}
                                alt=""
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    allData?.roomId
                                  );
                                  toast.info(t('info_Copied'), {
                                    position: "top-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  });
                                }}
                              />
                            )}
                          </b>
                        </div>
                        <div className="inner pl-4">
                          <span>{t('contest_details.password')}</span>
                          <b style={{ fontSize: "12px" }}>
                            {allData?.roomPassword === ""
                              ? "-"
                              : allData?.roomPassword?.length > 25 ? (
                                <marquee>{allData?.roomPassword}</marquee>
                              ) : (
                                allData?.roomPassword
                              )}{" "}
                            {allData?.roomPassword != "" && (
                              <img
                                src={CopyIcon}
                                alt=""
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    allData?.roomPassword
                                  );
                                  toast.info(t('info_Copied'), {
                                    position: "top-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  });
                                }}
                              />
                            )}
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="player-icons-captain">
                  <div className="row no-gutters">
                    <div className="col-8">
                      <div className="item" style={{ width: "6rem" }}>
                        <div className="icon">
                          <img src={Gamer} alt="" />
                        </div>
                        {t('contest_details.username')}
                      </div>
                    </div>
                    {/* <div className="col-5">&nbsp; </div> */}
                    <div
                      className="col-2 borderLeft"
                    // style={{ borderLeft: "1px solid #707070" }}
                    >
                      <div className="item">
                        <div className="icon">
                          <img src={Gun2} alt="" />
                        </div>
                        {t('contest_details.kills')}
                      </div>
                    </div>
                    <div
                      className="col-2 borderLeft"
                    // style={{ borderLeft: "1px solid #707070" }}
                    >
                      <div className="item">
                        <div className="icon">
                          <img src={Podium} alt="" />
                        </div>
                        {t('contest_details.rank')}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="player_captain contest mb-3">
                  {
                    captainData && (
                      // {captainData?.map((item, index) => {
                      //   console.log('item: ', item)
                      //   return (
                      <div className="item">
                        <div className="col-8">
                          <div className="row m-0 p-0">
                            <div className="icon">
                              <img
                                className="userNameIcon"
                                src={
                                  url.imageUrl +
                                  captainData?.userlevelImg
                                }
                                alt=""
                              // style={{ width: "40px", marginRight: "10px" }}
                              />
                            </div>
                            <div
                              className="d-flex my-auto h-100"
                              style={{ flexDirection: "column" }}
                            >
                              {/* <b style={{ fontSize: '16px', }}>{captainData?.inGameUserName}</b> */}
                              <b style={{
                                fontSize: "16px", display: 'block', width: '70px', whiteSpace: "nowrap", overflow: "hidden",
                                textOverflow: "ellipsis", marginTop: "0.5rem"
                              }}>
                                {captainData?.gamerjiName
                                  ? captainData?.gamerjiName
                                  : captainData?.inGameUserName}
                              </b>
                              <br />
                              {/* <small> */}
                              {/* {captainLevelData?.user && captainLevelData?.user?.phone ?
                                    "xxxxx" + captainLevelData?.user?.phone.slice(
                                      captainLevelData?.user?.phone?.length - 5
                                    )
                                    : ""

                                  } */}

                              {/* </small> */}
                              {allData?.status == "completed" ? (
                                captainData?.totalAmount > 0 ? (
                                  <div>
                                    <img
                                      src={TrophyYellow}
                                      alt=""
                                      className="coin_img"
                                    />{" "}
                                    Won{" "}
                                    {(allData?.contestFor[0]?.outCurrencyInfo
                                      ?.code === "INR" ||
                                      allData?.contestFor[0]?.outCurrencyInfo
                                        ?.code === "inr") &&
                                      "₹"}
                                    {(allData?.contestFor[0]?.outCurrencyInfo
                                      ?.code == "coin" ||
                                      allData?.contestFor[0]?.outCurrencyInfo
                                        ?.code == "diamond") && (
                                        <img
                                          src={
                                            url.imageUrl +
                                            allData?.contestFor[0]?.outCurrencyInfo
                                              ?.img?.default
                                          }
                                          alt=""
                                          className="coin_img"
                                        />
                                      )}{" "}
                                    {captainData?.totalAmount}
                                  </div>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              {premiumUser ? (
                                <div className="ps_player_front" >
                                  <img
                                    style={currentLang?.toLowerCase() == "ar" ? {
                                      width: "28px",
                                      height: "25px",
                                      marginRight: "20px",
                                    } : {
                                      width: "28px",
                                      height: "25px",
                                      marginLeft: "20px",
                                    }}
                                    src={PremiumLogoBlack}
                                  />{" "}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-2 d-flex justify-content-center align-items-center"
                          style={{ height: "40px" }}
                        >
                          <b className="">
                            {captainData?.kills === null ||
                              captainData?.kills === 0
                              ? "-"
                              : captainData?.kills}
                          </b>
                        </div>
                        <div
                          className="col-2 d-flex justify-content-center align-items-center"
                          style={{ height: "40px" }}
                        >
                          <b className="">
                            {captainData?.rank === null ||
                              captainData?.rank === 0
                              ? "-"
                              : captainData?.rank}
                          </b>
                        </div>
                      </div>
                    )
                    // );
                    // })
                  }

                  {/* <div className="load_more text-center">
                  <Link to="/" className="btn btn-primary">
                    Load More
                  </Link>
                </div> */}
                </div>
              </div>

              <RWebShare
                data={{
                  text: `${t('Profile.join_text', { name: "Gamerji" })}`,
                  url: `${url.siteUrl}?code=${allData?.shortCode}`,
                  title: `${t('Profile.join_me', { name: "Gamerji" })}`,
                }}
                onClick={() => console.info("share successful!")}
              >
                <div className="row friendship_items">
                  <div className="col-12">
                    <div className="box winning_breakup_popup_toggle">
                      <div className="icon">
                        <img src={Friendship} alt="" />
                      </div>
                      {t('contest_details.share_with_friends')}
                    </div>
                  </div>
                </div>
              </RWebShare>

              {showWinners === true && (
                <div
                  className="custom-popup rules custom_scroll show"
                  id="contact_popup"
                >
                  <div className="popup-head">
                    <h3>{t('contest_details.winning_breakup')}</h3>
                    <button
                      className="close-popup"
                      onClick={() => setShowWinners(false)}
                    >
                      <img src={Close} alt="" />
                    </button>
                  </div>
                  <div className="popup-body bg-yellow">
                    <div className="all_sub_screens_div_related_to_ads">
                      {allWinners?.pointpool &&
                        allWinners?.pointpool?.length !== 0 && (
                          <div
                            className="list-group main-tab w-90"
                            id="list-tab"
                            role="tablist"
                          >
                            <a
                              className="list-group-item list-group-item-action active"
                              id="list-home-list"
                              data-toggle="list"
                              href="#prize-1"
                            >
                              {t('contest_details.prize_pool')}
                            </a>
                            <a
                              className="list-group-item list-group-item-action"
                              id="list-profile-list"
                              data-toggle="list"
                              href="#point-2"
                            >
                              {t('contest_details.points')}
                            </a>
                          </div>
                        )}
                      <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane active" id="prize-1">
                          <div className="prize_pool mb-3 text-center p-2 w-90">
                            <p>{t('contest_details.prize_pool')}</p>
                            <h6>
                              {(allData?.contestFor[0]?.outCurrencyInfo?.code ===
                                "INR" ||
                                allData?.contestFor[0]?.outCurrencyInfo?.code ===
                                "inr") &&
                                "₹"}
                              {(allData?.contestFor[0]?.outCurrencyInfo?.code ==
                                "coin" ||
                                allData?.contestFor[0]?.outCurrencyInfo?.code ==
                                "diamond") && (
                                  <img
                                    src={
                                      url.imageUrl +
                                      allData?.contestFor[0]?.outCurrencyInfo?.img
                                        ?.default
                                    }
                                    alt=""
                                    className="coin_img_big"
                                  />

                                  // {allWinners?.currency?.code == "INR" && "₹"}
                                  // {allWinners?.currency?.code == "coin" && (
                                  //   <img
                                  //     src={
                                  //       url.imageUrl +
                                  //       allWinners?.currency?.img?.default
                                  //     }
                                  //     alt=""
                                  //     className="coin_img_big"
                                  //   />
                                )}{" "}
                              {allWinners?.prizePool}
                            </h6>
                          </div>
                          <div className="table-responsive total_table total_table_border mb-3 w-90">
                            <table>
                              <tbody>
                                {allWinners?.prizePoolInfo?.map(
                                  (item1, index1) => {
                                    return (
                                      <tr key={index1}>
                                        <td className={currentLang?.toLowerCase() == "ar" ? "text-left" : "text-left"}>
                                          {t('contest_details.rank')}:{" "}
                                          {item1?.rankTo !== "0" &&
                                            item1?.rankTo !== item1?.rankFrom
                                            ? item1?.rankFrom +
                                            "-" +
                                            item1?.rankTo
                                            : item1?.rankFrom}
                                        </td>
                                        <th className={currentLang?.toLowerCase() == "ar" ? "text-right" : "text-right"}>
                                          {(allData?.contestFor[0]
                                            ?.outCurrencyInfo?.code ===
                                            "INR" ||
                                            allData?.contestFor[0]
                                              ?.outCurrencyInfo?.code ===
                                            "inr") &&
                                            "₹"}
                                          {(allData?.contestFor[0]
                                            ?.outCurrencyInfo?.code ==
                                            "coin" ||
                                            allData?.contestFor[0]
                                              ?.outCurrencyInfo?.code ==
                                            "diamond") && (
                                              <img
                                                src={
                                                  url.imageUrl +
                                                  allData?.contestFor[0]
                                                    ?.outCurrencyInfo?.img
                                                    ?.default
                                                }
                                                alt=""
                                                className="coin_img"
                                              />
                                            )}{" "}
                                          {item1?.amount}
                                        </th>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {allWinners?.pointpool && (
                          <div className="tab-pane" id="point-2">
                            <div className="table-responsive total_table total_table_border mb-3 w-90">
                              <table>
                                <tbody>
                                  {allWinners?.pointpool?.map(
                                    (item1, index1) => {
                                      return (
                                        <tr key={index1}>
                                          <td className="text-left">
                                            {t('contest_details.rank')}:{" "}
                                            {item1?.rankTo !== "0"
                                              ? item1?.rankFrom +
                                              "-" +
                                              item1?.rankTo
                                              : item1?.rankFrom}
                                          </td>
                                          <th className="text-right">
                                            {item1?.point}
                                          </th>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {
                      !premiumUser && <Advertisement
                        screen={
                          localStorage.getItem("apk_screens_list") === null
                            ? { code: "no_results" }
                            : JSON.parse(
                              localStorage.getItem("apk_screens_list")
                            ).prizePoolPopup
                        }
                        screen_div_class_name=".all_sub_screens_div_related_to_ads"
                        screen_type="sub"
                      />
                    }

                  </div>
                </div>
              )}

              {rate === 0 ? (
                <Fragment>
                  <h2 className="title mb-3 mt-4">
                    {t('contest_details.rate_gamerji_e_sports_private_limited')}{" "}
                  </h2>
                  <div className="r-wrap">
                    <div className="r-image">
                      <div className="img" onClick={() => onChangeRate(1)}>
                        <img src={R1} alt="" />
                      </div>
                      <div className="img" onClick={() => onChangeRate(2)}>
                        <img src={R2} alt="" />
                      </div>
                      <div className="img" onClick={() => onChangeRate(3)}>
                        <img src={R3} alt="" />
                      </div>
                      <div className="img" onClick={() => onChangeRate(4)}>
                        <img src={R4} alt="" />
                      </div>
                      <div className="img" onClick={() => onChangeRate(5)}>
                        <img src={R5} alt="" />
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <h2 className="title mb-3 mt-4">{t('contest_details.you_provided_ratings')} </h2>
                  <div className="r-wrap">
                    <div className="text-center">
                      <div className="img">
                        <img
                          src={
                            rate === 1
                              ? R1
                              : rate === 2
                                ? R2
                                : rate === 3
                                  ? R3
                                  : rate === 4
                                    ? R4
                                    : R5
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
            {/* <--------------------- Contest Player tab --------------------> */}
            <div className="tab-pane" id="list-2">
              <div className="player_icons">
                <div className="row no-gutters">
                  <div className="col-3">
                    <div className="item" style={{ width: "95px" }}>
                      <div className="icon">
                        <img src={Gamer} alt="" />
                      </div>
                      {t('contest_details.username')}
                    </div>
                  </div>
                  <div className="col-5">&nbsp; </div>
                  <div className="col-2">
                    <div className="item">
                      <div className="icon">
                        <img src={Gun2} alt="" />
                      </div>
                      {t('contest_details.kills')}
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="item">
                      <div className="icon">
                        <img src={Podium} alt="" />
                      </div>
                      {t('contest_details.rank')}
                    </div>
                  </div>
                </div>
              </div>

              <div className="player_list contest">
                {
                  captainData && (
                    // {captainData?.map((item, index) => {
                    //   return (
                    <div className="item active">
                      <div className="inner inner_big">
                        <div className="icon">
                          <img
                            style={{ width: "30px", height: "30px" }}
                            src={
                              url.imageUrl +
                              captainData?.userlevelImg
                            }
                            alt=""
                          />
                        </div>
                        {/* <b>{captainData?.inGameUserName}</b> */}
                        <b>
                          {captainData?.gamerjiName ? (
                            captainData?.gamerjiName?.length >
                              16 ? (
                              <marquee>
                                {captainData?.gamerjiName}
                              </marquee>
                            ) : (
                              captainData?.gamerjiName
                            )
                          ) : captainData?.inGameUserName?.length > 16 ? (
                            <marquee>{captainData?.inGameUserName}</marquee>
                          ) : (
                            captainData?.inGameUserName
                          )}
                        </b>
                        <br />
                        {/* <small> */}
                        {/* {captainData?.user && captainData?.user?.phone ?
                              "xxxxx" + captainData?.user?.phone.slice(
                                captainData?.user?.phone?.length - 5
                              )
                              : ""

                            } */}
                        {/* </small> */}
                        {allData?.status == "completed" ? (
                          captainData?.totalAmount > 0 ? (
                            <div>
                              <img
                                src={TrophyYellow}
                                alt=""
                                className="coin_img"
                              />{" "}
                              Won{" "}
                              {(allData?.contestFor[0]?.outCurrencyInfo?.code ===
                                "INR" ||
                                allData?.contestFor[0]?.outCurrencyInfo?.code ===
                                "inr") &&
                                "₹"}
                              {(allData?.contestFor[0]?.outCurrencyInfo?.code ==
                                "coin" ||
                                allData?.contestFor[0]?.outCurrencyInfo?.code ==
                                "diamond") && (
                                  <img
                                    src={
                                      url.imageUrl +
                                      allData?.contestFor[0]?.outCurrencyInfo?.img
                                        ?.default
                                    }
                                    alt=""
                                    className="coin_img"
                                  />
                                )}{" "}
                              {captainData?.totalAmount}
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {premiumUser ? (
                          <div className="ps_player " >
                            <img
                              style={currentLang?.toLowerCase() == "ar" ? {
                                width: "28px",
                                height: "25px",
                                marginRight: "20px",
                              } : {
                                width: "28px",
                                height: "25px",
                                marginLeft: "20px",
                              }}
                              src={PremiumLogoBlack}
                            />{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="inner">
                        <b>
                          {captainData?.kills === null ||
                            captainData?.kills === 0
                            ? "-"
                            : captainData?.kills}
                        </b>
                      </div>
                      <div className="inner">
                        <b>
                          {captainData?.rank === null ||
                            captainData?.rank === 0
                            ? "-"
                            : captainData?.rank}
                        </b>
                      </div>
                    </div>
                  )
                  //   );
                  // })
                }
                {allPlayersData?.map((item, index) => {
                  if (captainData && captainData?._id !== item?._id) {
                    return (
                      <div className="item" key={index}>
                        <div className="inner inner_big">
                          <div className="icon">
                            {
                             item?.companyCode?.toLowerCase()==='vi' && (item?.userlevelImg===undefined || item?.userlevelImg==null || item?.userlevelImg==="")
                              ?
                              <img style={{ width: "30px", height: "30px" }} src={placeHolder} alt=""  />
                              :
                              <img
                              style={{ width: "30px", height: "30px" }}
                              src={
                                url.imageUrl +
                                item?.userlevelImg
                              }
                              alt=""
                            />
                            }
                          </div>
                          {/* <b>{item?.inGameUserName}</b> */}
                          <b>
                            {item?.gamerjiName ? (
                              item?.gamerjiName?.length > 16 ? (
                                <marquee>
                                  {item?.gamerjiName}
                                </marquee>
                              ) : (
                                item?.gamerjiName
                              )
                            ) : item?.inGameUserName?.length > 16 ? (
                              <marquee>{item?.gamerjiName}</marquee>
                            ) : (
                              item?.gamerjiName
                            )}
                          </b>

                          <br />
                          {/* <small>

                              {item?.user && item?.user?.phone ?
                                "xxxxx" + item?.user?.phone.slice(
                                  item?.user?.phone?.length - 5
                                )
                                : ""

                              }
                            </small> */}
                          {allData?.status == "completed" ? (
                            item?.totalAmount > 0 ? (
                              <div>
                                <img
                                  src={TrophyYellow}
                                  alt=""
                                  className="coin_img"
                                />{" "}
                                Won
                                {allData?.contestFor[0]?.outCurrencyInfo
                                  ?.code === "INR" && "₹"}
                                {(allData?.contestFor[0]?.outCurrencyInfo
                                  ?.code == "coin" ||
                                  allData?.contestFor[0]?.outCurrencyInfo
                                    ?.code == "diamond") && (
                                    <img
                                      src={
                                        url.imageUrl +
                                        allData?.contestFor[0]?.outCurrencyInfo
                                          ?.img?.default
                                      }
                                      alt=""
                                      className="coin_img"
                                    />
                                  )}{" "}
                                {item?.totalAmount}
                              </div>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          {item?.isPremium ? (
                            <div className="ps_player">
                              <img
                                style={currentLang?.toLowerCase() == "ar" ? {
                                  width: "28px",
                                  height: "25px",
                                  marginRight: "20px",
                                } : {
                                  width: "28px",
                                  height: "25px",
                                  marginLeft: "20px",
                                }}
                                src={PremiumLogoBlack}
                              />{" "}
                            </div>
                          ) : (
                            ""
                          )}
                          <b></b>
                        </div>

                        <div className="inner">
                          <b>
                            {item?.kills === null || item?.kills === 0
                              ? "-"
                              : item?.kills}
                          </b>
                        </div>
                        <div className="inner">
                          <b>
                            {item?.rank === null || item?.rank === 0
                              ? "-"
                              : item?.rank}
                          </b>
                        </div>
                      </div>
                    );
                  }
                })}
                {!isLastPage && allPlayersData.length > 0 && (
                  <div className="load_more text-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => getLoadMore()}
                    >
                      {loader === true ? "Loading ..." : "Load More"}
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* <--------------------- Contest Rules tab --------------------> */}
            <div className="tab-pane" id="list-3">
              <div className="tab_content">
                <p>{ReactHtmlParser(rules)}</p>
              </div>
            </div>
          </div>
        </div>
        {
          !premiumUser && <Advertisement
            screen={
              localStorage.getItem("apk_screens_list") === null
                ? { code: "no_results" }
                : JSON.parse(localStorage.getItem("apk_screens_list"))
                  .contestDetails
            }
            screen_div_class_name=".all_sub_screens_div_related_to_ads"
            screen_type="sub"
          />
        }

      </div>

    </div>
  );
}

export default ContestDetails;
