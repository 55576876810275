import React from "react";
import { ToastContainer, toast } from "react-toastify";
import Previous from "../../assets/images/previous.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import rightArrow from "../../assets/images/right-arrow.svg";
import moment from "moment";
import DatePopUp from "./DatePopUp";
import { getGameRules, getGameTypeById, getScrimById, updateScrimById, viewScrimById } from "../../apis";
import dateIcon from "../../assets/images/date-icon.svg";
import timeIcon from "../../assets/images/clock.png";
import "react-datetime/css/react-datetime.css";
import TimePicker from "./TimePicker";
import DropDownPop from "./DropDownPop";
import TextEditor from "./TextEditor";
import { useTranslation } from "react-i18next";
import { constants } from "../../constants/constants";

const ScrimCreate = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  // console.log(data)
  const params = useParams();
  const [title, setTitle] = useState(t("private_contest.create_contest"));
  const [activeTab, setActiveTab] = useState("Basic Details");
  const [loader, setLoader] = useState(false);
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    if (params?.id) {
      setTitle("Edit Contest");
    }
  }, []);
  const createPayload = () => {
    let hostedBy = localStorage.getItem(constants.profileLite);
    hostedBy = JSON.parse(hostedBy)?.item?.user_id;
    let createdBy = hostedBy;
    let id = "";
    dataId.map((ele, index) => {
      if (ele.name === selectedButton) id = ele._id;
    });
    let obj = {
      noOfPlayerCapacity: numberOfPlayer,
      noOfWinner: numberOfWinner,
      title: scrimName,
      date: date,
      time: time,
      type: selectedButton,
      gameType: id,
      currency: currency,
      feeAmount: feeAmount,
      currencyImg: currencyImg,
      country: JSON.parse(localStorage.getItem("userCountryObj"))?._id,
      // country: JSON.parse(localStorage.getItem("countryId")),
      hostedBy: hostedBy,
      createdBy: createdBy,
      game: game,
      updatedBy: createdBy,
      platform: "WebApp",
    };
    if (dropDownNames.length > 0) obj[`title1`] = { name: dropDownNames[0][0], value: title1 };
    if (dropDownNames.length > 1) {
      obj[`title2`] = { name: dropDownNames[1][0], value: title2 };
    }
    // console.log(obj)
    setPayload1({ ...payload1, ...obj });
  };
  const handleTabClick = (tab) => {
    if (tab == "Basic Details") {
      setActiveTab("Basic Details");
    }

    if (tab == "Game Rules") {
      let err = check1();
      if (!err) {
        createPayload();
        let obj = payload1;

        obj = { ...payload1, rules: rulesData };
        //console.log("obj", obj);
        localStorage.setItem("payload", JSON.stringify(obj));
        setActiveTab("Game Rules");
      } else showError(err);
    }
  };
  const showError = (err) => {
    // console.log("showeeror", err)
    toast.error(err, {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // Basic Details - sttart ************************

  // console.log(location);
  var link = "/create-scrim";
  if (params?.id) {
    link = "/edit-scrim/" + params.id;
  }
  const [scrimName, setScrimName] = useState("");
  const [error, setError] = useState("");
  const [showDate, setShowDate] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [numberOfPlayer, setNumberOfPlayer] = useState("");
  const [numberOfWinner, setNumberOfWinner] = useState("");
  const [gameType, setGameType] = useState([]);
  const [titles, setTitles] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);
  const [dropDownNames, setDropDownnames] = useState([]);
  const [dropDownValues, setDropDownValues] = useState([]);
  const [dataId, setDataId] = useState([]);
  const [scrimDetails, setScrimDetails] = useState([]);
  const [currency, setCurrency] = useState("");
  const [feeAmount, setFeeAmount] = useState("");
  const [currencyImg, setCurrencyImg] = useState("");
  const [editDropDown, setEditDropDown] = useState({});
  // scrimbutton

  const [selectedButton, setSelectedButton] = useState("");
  const [showTitle1, setShowTitle1] = useState();
  const [showTitle2, setShowTitle2] = useState();
  const [title1, setTitle1] = useState("");
  const [bufferTime, setBufferTime] = useState("");
  const [title2, setTitle2] = useState("");

  const handleButtonClick = (type) => {
    // console.log("clicked", type);
    setSelectedButton(type);
    setShowDropDown(true);

    setNames(type);
  };
  //  console.log(titles[type])
  const setNames = (type) => {
    // console.log("called", type);

    let names = [];
    for (var i = 0; i < titles.length; i++) {
      if (titles[i].name === type) {
        let data = titles[i].value;

        // console.log(data)

        for (let i = 0; i < data.titles.length; i++) {
          // console.log(data.titles[i], data[`title${i + 1}Values`].length > 0)
          if (data[`title${i + 1}Values`].length > 0) {
            var labelValue = [];
            for (let j = 0; j < data[`title${i + 1}Values`].length; j++) {
              labelValue.push({
                value: data[`title${i + 1}Values`][j]["value"],
                label: data[`title${i + 1}Values`][j]["value"],
              });
            }
            // console.log(labelValue)
            names.push([data.titles[i].name, labelValue]);
          }
        }
        // console.log(names, "patel");
        setDropDownnames([...names]);
        // setTitle1(names[0][1].value);
        // if (dropDownNames.length > 1) setTitle2(names[1][1].value);
      }
    }
  };
  //

  const showDateandTime = () => {
    setShowDate(false);
    setShowTime(false);
  };

  let tyear = new Date().getFullYear();
  let month = ("0" + (new Date().getMonth() + 1)).slice(-2);
  let day = ("0" + new Date().getDate()).slice(-2);
  const todayDate = day + "/" + month + "/" + tyear;
  const hr = new Date().getHours().toString();
  const min = new Date().getMinutes().toString();
  // console.log("todayDate", todayDate);
  var hours = hr % 12 ? hr % 12 : 12;
  hours = hours < 10 ? "0" + hours : hours;
  var minutes = min < 10 ? "0" + min : min;
  var AMPM = hr >= 12 ? "PM" : "AM";
  const todayTime = hours + ":" + minutes + " " + AMPM;

  const check = () => {
    // console.log(time)
    let [t, modifier] = time?.split(" ");
    let [hour, m] = t.split(":");
    // console.log(hour, m, modifier);
    if (modifier == "PM") {
      hour = parseInt(hour) + 12;
    }

    let newT = hour + ":" + m;
    // console.log(bufferTime);
    let t1 = moment(new Date()).add(bufferTime, "m").format("hh:mm a");
    // console.log(newT, t1, newT > t1)

    //

    let [nt, nmodifier] = t1.split(" ");
    let [nhour, nm] = nt.split(":");
    // console.log(nhour, nm, modifier);
    if (nmodifier == "PM" && nhour != 12) {
      nhour = parseInt(nhour) + 12;
      // console.log(nhour, parseInt(nhour));
    }
    let nt1 = nhour + ":" + nm;
    // console.log(nhour);
    if (scrimName?.length === 0) {
      let value = t("error_please_enter_a_scrim_name"); //"Please enter a scrim name";
      return value;
    }
    if (selectedButton === "") {
      let value = t("error_please_select_a_scrim_type"); // "Please select a scrim type";
      return value;
    }
    // console.log(
    //   date === moment(new Date()).format("DD/MM/YYYY"),
    //   nt,
    //   newT,
    //   nt1
    // );
    if (date === moment(new Date()).format("DD/MM/YYYY") && newT < nt1) {
      let value = t("error_please_select_future_time"); // "Please select a future time";
      return value;
    }
    // console.log(title1, title2);
    if (!title1 || title1.length === 0) {
      let value =
        t("error_please_select") + " " + (dropDownNames?.[0]?.[0].toString().toLowerCase() == "map" ? t("contest.map") : dropDownNames?.[0]?.[0]); //`Please Select ${dropDownNames?.[0]?.[0]}`;
      return value;
    }
    if (dropDownNames?.length > 1) {
      if (!title2 || title2.length === 0) {
        let value =
          t("error_please_select") + " " + (dropDownNames?.[1]?.[0].toString().toLowerCase() == "map" ? t("contest.map") : dropDownNames?.[1]?.[0]); //`Please Select ${dropDownNames?.[1]?.[0]}`;
        return value;
      }
    }
    if (numberOfPlayer?.length === 0) {
      let value = t("error_please_enter_no_of_entries_that_can_enter");
      return value;
    }
    if (numberOfPlayer?.length > 3) {
      let value = t("error_number_of_entries_is_invalid"); // "Number of entries is invalid";
      return value;
    }
    if (numberOfWinner?.length === 0) {
      let value = t("error_please_enter_numbers_of_winners"); //"Please enter number of winners";
      return value;
    }
    if (numberOfWinner?.length > 3) {
      let value = t("error_winners_count_is_invalid"); // " Winners Count is invalid";
      return value;
    }
    if (parseInt(numberOfWinner) <= 0) {
      let value = t("error_winners_count_is_invalid"); // "Number of Winner should be greater than 0";
      return value;
    }
    if (parseInt(numberOfPlayer) <= 0) {
      let value = t("error_no_of_entries_should_be_greater_then_zero"); // "Number of entries should be greater than 0";
      return value;
    }
    if (parseInt(numberOfWinner) > parseInt(numberOfPlayer)) {
      let value = t("error_winners_cannot_exceed_number_of_entries"); // "Winners cannot exceed number of entries";
      return value;
    }
    var letterNumber = /^[0-9a-zA-Z\s]+$/;
    if (!scrimName?.match(letterNumber)) {
      let value = t("error_please_enter_letter_and_numbers_only"); // "Please Enter Letters and Numbers Only";
      return value;
    }
  };
  const showDatee = () => {
    // console.log('clicked');
    setShowDate(true);
    setShowTime(false);

    // console.log(showDate);
  };
  const showTimee = () => {
    // console.log("clicked")
    setShowTime(true);
    setShowDate(false);
  };
  const getDropDownValue = (selectedOption, event) => {
    // console.log(selectedOption);
    // console.log(event);
    setDropDownValues([...dropDownValues, { name: event.name, value: selectedOption.value }]);
  };

  const fetchData = async () => {
    setLoader(true);
    let gameId = localStorage.getItem("activeGameId");
    // console.log("fetchdata", gameId);
    const res = await getGameTypeById({
      game: gameId,
    });
    // console.log(res);
    //console.log("currency", res);
    localStorage.setItem("currency", JSON.stringify(res?.hostSettings?.currency?.[0]?.code || null));
    if (res?.success) {
      // console.log(res.data);
      let temp = [];
      let titles = [];
      setDataId(res.data);
      res?.data.map((ele, index) => {
        temp[index] = ele.name;
      });
      // console.log("temp",temp);
      setGameType([...temp]);
      res.data.forEach((element, index) => {
        // if(element.name==="OPEN SQUAD")
        // console.log(element.game.titles)
        var obj = {};
        obj["name"] = element.name;
        obj["value"] = element.game;
        titles.push(obj);
      });
      // console.log(titles, "titles");
      setTitles(titles);
      setCurrency(res.hostSettings?.currency?.[0]?._id); //res.hostSettings.currency[0].img.default
      setFeeAmount(res.hostSettings?.hostFees);
      setCurrencyImg(res.hostSettings?.currency?.[0]?.img);
      setBufferTime(res.hostSettings?.currency?.[0]?.BufferTime);
    } else {
      //console.log("error");
      // showError(res?.data?.[0]?.msg)
    }
    setLoader(false);
  };
  useEffect(async () => {
    await fetchData();
  }, []);

  useEffect(async () => {
    // console.log("fetch data", params);
    if (params?.id) localStorage.removeItem("payload");
    let localData = JSON.parse(localStorage.getItem("payload"));
    if (localData) {
      // console.log(localData.type)
      setSelectedButton(localData?.type);
      setScrimName(localData?.title);
      setNumberOfWinner(localData?.noOfWinner);
      setNumberOfPlayer(localData?.noOfPlayerCapacity);
      setRulesData(localData?.rules);
      var d = localData?.date;
      // console.log('data',d, moment(localData?.time).format("hh:mm A"));
      let date = localData?.date;
      let time = localData?.time;
      // console.log(date, time)
      setDate(date);
      setTime(time);
      setRulesData(localData?.rules);
      setNames(localData?.type);
      setTitle1(localData?.title1?.value);
      if (localData?.title2) setTitle2(localData?.title2?.value);

      // setDropDownValues([...dropDownValues, { "name": "MAP", "value": "barmuda" }])

      // for(let i=0; i<titl.length ;i++){
      //   console.log("titles", titl[i])
      //   setEditDropDown({...editDropDown, [titl[i].name]:titl[i].value})
      // }
      // console.log(editDropDown);
      setShowDropDown(true);
    } else {
      if (params?.id && titles.length > 0) {
        var res = await getScrimById({
          id: params?.id,
        });
        // console.log(res.data[0]?.param.titles, "***************");

        setSelectedButton(res.data[0]?.param.gameType?.name);
        setScrimName(res.data[0]?.param.title);
        setNumberOfWinner(res.data[0]?.param.noOfWinner);
        setNumberOfPlayer(res.data[0]?.param.noOfPlayerCapacity);
        setRulesData(res?.data[0]?.param?.rules);
        var d = res.data[0]?.param.date;
        let date = moment(d).format("DD/MM/YYYY");
        let time = moment(d).format("hh:mm A");
        setDate(date);
        setTime(time);
        setNames(res.data[0]?.param.gameType?.name);
        setTitle1(res.data[0]?.param.titles[0]?.value);
        setTitle2(res.data[0]?.param.titles[1]?.value);

        setScrimDetails(res.data[0].param);
        // setDropDownValues([...dropDownValues, { "name": "MAP", "value": "barmuda" }])
        let titl = res.data[0]?.param.titles;
        // console.log(titl);
        // for(let i=0; i<titl.length ;i++){
        //   console.log("titles", titl[i])
        //   setEditDropDown({...editDropDown, [titl[i].name]:titl[i].value})
        // }
        // console.log(editDropDown);
        setShowDropDown(true);
      } else {
        // localStorage.removeItem('payload')
        setDate(todayDate);
        setTime(todayTime);
      }
    }
  }, [params.id, titles]);

  // useEffect(() => {
  //   let v = check();
  //   setError(v);
  //   // showError(error)
  // }, [error, check]);

  const checkValidation = () => {
    check();

    let id = "";
    dataId.map((ele, index) => {
      if (ele.name === selectedButton) id = ele._id;
    });
    // console.log(id)

    if (error) {
      // console.log("check valid error", error)
      showError(error);
      setValidation(false);
    } else {
      var obj = {
        noOfPlayerCapacity: numberOfPlayer,
        noOfWinner: numberOfWinner,
        title: scrimName,
        date: date,
        time: time,
        type: selectedButton,
        gameType: id,
        currency: currency,
        feeAmount: feeAmount,
        currencyImg: currencyImg,
        // country: JSON.parse(localStorage.getItem("userCountryObj"))?._id,
        country: JSON.parse(localStorage.getItem("countryId")),
      };
      if (dropDownNames.length > 0) obj[`title1`] = { name: dropDownNames[0][0], value: title1 };
      if (dropDownNames.length > 1) {
        obj[`title2`] = { name: dropDownNames[1][0], value: title2 };
      }
      // console.log(obj)
      // for (let i = 0; i < dropDownValues.length; i++) {
      //   obj[`title${i + 1}`] = dropDownValues[i]
      //   console.log(`title${i + 1}`, dropDownValues[i])
      // }
      // console.log(obj);
      // console.log("validated")
      // setPayload(obj);
      setValidation(true);
    }
  };
  const buttonHandler = () => {
    checkValidation();
    // console.log(validation)
    if (validation) setActiveTab("Game Rules");
    else {
      setActiveTab("Basic Details");
    }
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    // console.log(date, time, numberOfPlayer, numberOfWinner);
  };
  const getDate = (date) => {
    // console.log(date);
    setDate(date);
    setShowDate(false);
  };
  const getTime = (time) => {
    // console.log(time);
    setTime(time);
    setShowTime(false);
    // console.log(showTime);
    // console.log(moment(new Date()).format('HH:MM a')>=moment(date).add(30,'m').toDate(),moment(new Date(date)).add(30,'m').toDate())
  };
  const getTitle1 = (title) => {
    // console.log(title);
    setTitle1(title);
    setShowTitle1(false);
  };
  const getTitle2 = (title) => {
    // console.log(title);
    setTitle2(title);
    setShowTitle2(false);
  };
  const showDropDownValues = () => {
    setShowTitle1(false);
    setShowTitle2(false);
  };

  const setDropDownHandler1 = () => {
    // console.log("called");
    // setShowDropDown(true)
    // setDropDownValues(value)
    // console.log(dropDownNames)
    setShowTitle2(false);
    setShowTitle1(true);
  };
  const setDropDownHandler2 = () => {
    // console.log("called");
    // setShowDropDown(true)
    // setDropDownValues(value)
    // console.log(dropDownNames)
    setShowTitle1(false);
    setShowTitle2(true);
  };

  // ***********Basic Details - end ***************

  useEffect(() => {
    // setLocalData(JSON.parse(localStorage.getItem('payload')))
    if (activeTab === 'Game Rules' && rulesData === '') {
      fetchRules();
    }
  }, [activeTab]);
  const game = localStorage.getItem("activeGameId");
  let fetchRules = async () => {
    const res = await getGameRules({
      game: game,
    });
    if (res) {
      // console.log(res)
      setRulesData(res?.data?.[0]?.rules);
      // console.log(rulesData)
    }
  };
  const [payload1, setPayload1] = useState({});
  const [rulesData, setRulesData] = useState("");
  const ruleSet = (val) => {
    setRulesData(val);
  };
  const check1 = () => {
    let [el, modifier] = time.split(" ");
    let [hour, m] = el.split(":");
    // console.log(hour, m, modifier);
    if (modifier == "PM" && hour != 12) {
      hour = parseInt(hour) + 12;
    }

    let newT = hour + ":" + m;

    let t1 = moment(new Date()).add(bufferTime, "m").format("hh:mm A");

    //

    let [nt, nmodifier] = t1.split(" ");
    let [nhour, nm] = nt.split(":");
    // console.log(nhour, nm, modifier);
    if (nmodifier == "PM" && nhour != 12) {
      nhour = parseInt(nhour) + 12;
    }
    let nt1 = nhour + ":" + nm;
    // console.log(newT, nt1)

    if (scrimName.length == 0) {
      let value = t("error_please_enter_a_scrim_name");
      //console.log(value);
      return value;
    }
    var letterNumber = /^[0-9a-zA-Z\s]+$/;
    if (!scrimName.match(letterNumber)) {
      let value = t("error_please_enter_letter_and_numbers_only");
      //console.log(value); // "Please Enter Letters and Numbers Only";
      return value;
    }
    if (selectedButton === "") {
      let value = t("error_please_select_a_scrim_type"); // "Please select a scrim type";
      return value;
    }
    if (date === moment(new Date()).format("DD/MM/YYYY") && newT < nt1) {
      let value = t("error_please_select_future_time"); // "Please select future time";
      return value;
    }
    if (!title1 || title1.length === 0) {
      let value =
        t("error_please_select") + " " + (dropDownNames?.[0]?.[0].toString().toLowerCase() == "map" ? t("contest.map") : dropDownNames?.[0]?.[0]); //`Please Select ${dropDownNames?.[0]?.[0]}`;
      return value;
    }
    if (dropDownNames?.length > 1) {
      if (!title2 || title2.length === 0) {
        let value =
          t("error_please_select") + " " + (dropDownNames?.[1]?.[0].toString().toLowerCase() == "type" ? t("contest.type") : dropDownNames?.[1]?.[0]); // `Please Select ${dropDownNames?.[1]?.[0]}`;
        return value;
      }
    }
    if (numberOfPlayer?.length === 0) {
      let value = t("error_please_enter_no_of_entries_that_can_enter");
      return value;
    }
    if (numberOfPlayer?.length > 3) {
      let value = t("error_number_of_entries_is_invalid");
      return value;
    }
    if (numberOfWinner?.length === 0) {
      let value = t("error_please_enter_winners_count");
      return value;
    }
    if (numberOfWinner?.length > 3) {
      let value = t("error_winners_count_is_invalid");
      return value;
    }
    if (parseInt(numberOfWinner) <= 0) {
      let value = t("error_no_of_winner_should_greater_then_zero");
      return value;
    }
    if (parseInt(numberOfPlayer) <= 0) {
      let value = t("error_no_of_entries_should_be_greater_then_zero");
      return value;
    }
    if (parseInt(numberOfWinner) > parseInt(numberOfPlayer)) {
      let value = t("error_winners_cannot_exceed_number_of_entries");
      return value;
    }
  };
  const checkHandler = () => {
    // console.log("called")
    let err = check1();
    if (err) showError(err);
    else {
      createPayload();
      setActiveTab("Game Rules");
    }
  };

  const formSubmit = async (data) => {
    var newTime = data["time"].split(" ");
    // console.log(newTime, newTime[0], newTime[1], newTime[2])
    if (newTime[1] == "PM") {
      var temp = newTime[0].split(":");
      temp[0] = parseInt(temp[0]) + 12;
      newTime[0] = temp[0].toString() + ":" + temp[1];
    }
    newTime = newTime[0] + ":00";
    // console.log(newTime)
    var newDate = data["date"] + " " + newTime;
    // var newDate = data.payload['date'] + " " + newTime + ":00"
    const [dateString, timeString] = newDate.split(" ");
    const [day, month, year] = dateString.split("/");
    const [hour, minute, second] = timeString.split(":");
    const dateObj = new Date(+year, +month - 1, +day, +hour, +minute, +second);
    // console.log(dateObj.toISOString())
    // console.log(dateObj)
    // console.log("new date=>", newDate);
    var datee = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
    // console.log(datee, new Date(datee))
    data = { ...data, date: dateObj.toISOString(), time: dateObj.toISOString() };

    const res = await updateScrimById({
      id: params?.id,
      data: data,
    });
    // console.log(res)
    if (res) {
      localStorage.removeItem("payload");
      window.location.href = "/manage-scrim/" + params?.id;
    } else {
      showError(res?.data?.[0].msg);
    }
  };

  const checkGameRules = () => {
    // console.log("rules Date")
    if (rulesData.length < 25) {
      var msg = t("error_rules_must_be_within_characters");
      showError(msg);
    } else {
      // console.log("yes")

      let obj = payload1;
      obj = { ...payload1, rules: rulesData };
      // console.log("obj", obj)
      setPayload1(obj);
      localStorage.setItem("payload", JSON.stringify(obj));
      if (params?.id) {
        // console.log("edited")
        formSubmit(obj);
      } else navigate("/scrim-summary");
      // window.location.href = '/scrim-summary'
      // console.log(obj)
    }
    // console.log(payload1)
  };
  const additionalString = "Profile";

  return (
    <div className="inner-wrap" id="my_contest_main_inner">
      <ToastContainer />
      <div className="header">
        <div className="back-btn" onClick={() => navigate(-1)}>
          <a>
            <img src={Previous} alt="" />
          </a>
        </div>
        <h2>{title}</h2>
        <div className="wallet-btn">
          {/* <Link to="/">
            <img src={Wallet} alt="" />
            Wallet
          </Link> */}
        </div>
      </div>
      <div className="body mCustomScrollbar _mCS_1 bottom-btn-body mycontest_main pt-0">
        <div className="all_sub_screens_div_related_to_ads ">
          <div className="tab-content h-100" id="nav-tabContent">
            <div className="margin-top create-scrim-title-section ">
              <button
                onClick={() => handleTabClick("Basic Details")}
                className={`create-scrim-button border-left-radius ${activeTab === "Basic Details" ? "create-scrim-button-selected" : ""}`}>
                {t("private_contest.basic_details")}
              </button>
              <button
                onClick={() => handleTabClick("Game Rules")}
                // className="create-scrim-button border-right-radius"
                className={`create-scrim-button border-right-radius ${activeTab === "Game Rules" ? "create-scrim-button-selected" : ""}`}>
                {" "}
                {t("private_contest.game_rules")}
              </button>
            </div>
            <div>
              {activeTab === "Basic Details" && (
                //    <BasicDetails showError = {showError} handleTabClick={handleTabClick} setPayload={setPayloadHandler} validation={validation} setValidationHandler={setValidationHandler} payload={payload}  />

                <main className="scrim-basicDetails ">
                  <input
                    maxLength={64}
                    placeholder={t("private_contest.enter_contest_name")}
                    className="dobns_input_component"
                    onChange={(e) => setScrimName(e.target.value)}
                    // defaultValue={payload.title ? payload?.title : scrimDetails?.title}
                    defaultValue={scrimName ? scrimName : ""}></input>
                  <p className="text-center w-100" style={{ fontSize: "19px" }}>
                    {t("private_contest.select_scrim_type")}
                  </p>
                  {/* {console.log("ScrimType",gameType)} */}
                  {/* <ScrimTypeButton gameType={gameType}/> */}
                  <div>
                    {gameType.length === 1 ? (
                      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", justifyContent: "center", textAlign: "center" }}>
                        {gameType.map((type) => (
                          <div
                            key={type}
                            style={{
                              marginBottom: "10px",
                              flexBasis: "calc(50% - 5px)",
                            }}>
                            <div
                              className={`btn btn-dark scrim-btns border-radius ${selectedButton === type ? "scrim-btns-selected" : ""}`}
                              onClick={() => handleButtonClick(type)}>
                              {type}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                        {gameType.map((type) => (
                          <div
                            key={type}
                            style={{
                              marginBottom: "10px",
                              flexBasis: "calc(50% - 5px)",
                            }}>
                            <div
                              className={`btn btn-dark scrim-btns border-radius ${selectedButton === type ? "scrim-btns-selected" : ""}`}
                              onClick={() => handleButtonClick(type)}>
                              {type}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <form onSubmit={onSubmitHandler}>
                    <div className="flex gap">
                      <div className="form-group width">
                        <div style={{ marginBottom: -10, marginLeft: 15 }}>
                          <label>{t("private_contest.pick_a_date")}</label>
                        </div>
                        <div className="dobns_input_component">
                          <button
                            className="dobns_input"
                            // placeholder="DD/MM/YYY"
                            onClick={showDatee}>
                            {date}
                          </button>
                          <div className="dobns_icon">
                            {/* <button   onClick={showDatee} className="date-popup-button"> */}
                            <img src={dateIcon} alt="" />
                            {/* </button> */}
                          </div>
                        </div>
                      </div>
                      {showDate ? <DatePopUp cancel={true} getDate={getDate} showDateandTime={showDateandTime} /> : ""}
                      <div className="form-group  width">
                        <div style={{ marginBottom: -10, marginLeft: 15 }}>
                          <label style={{ textAlign: "center" }}>{t("private_contest.select_time")}</label>
                        </div>
                        <div className="dobns_input_component">
                          <input className="dobns_input" onClick={showTimee} placeholder="HH:MM" value={time} />
                          <div className="dobns_icon">
                            <img src={timeIcon} alt="" />
                          </div>
                        </div>
                      </div>
                      {showTime ? (
                        <TimePicker
                          cancel={true}
                          getTime={getTime}
                          getDate={date}
                          todayDate={todayDate}
                          todayTime={time}
                          showDateandTime={showDateandTime}
                        />
                      ) : (
                        ""
                      )}
                    </div>

                    {showDropDown ? (
                      <>
                        <div className="flex gap">
                          <>
                            <div className="form-group width">
                              <div style={{ marginBottom: -10, marginLeft: 15 }}>
                                <label>
                                  {dropDownNames?.[0]?.[0].toString().toLowerCase() == "map" ? t("contest.map") : dropDownNames?.[0]?.[0]}
                                </label>
                              </div>
                              <div className="dobns_input_component">
                                <input
                                  className="dobns_input"
                                  onClick={setDropDownHandler1}
                                  value={title1}
                                  placeholder={dropDownNames?.[0]?.[0].toString().toLowerCase() == "map" ? t("contest.map") : dropDownNames?.[0]?.[0]}
                                />
                              </div>
                            </div>

                            {showTitle1 ? (
                              <DropDownPop
                                cancel={true}
                                getTitle={getTitle1}
                                showDropDownValues={showDropDownValues}
                                values={dropDownNames?.[0][1].map((el) => el.value)}
                              />
                            ) : (
                              ""
                            )}
                          </>
                          {dropDownNames.length > 1 ? (
                            <>
                              <div className="form-group width">
                                <div style={{ marginBottom: -10, marginLeft: 15 }}>
                                  <label>
                                    {dropDownNames[1][0].toString().toLowerCase() == "type"
                                      ? t("contest.type")
                                      : dropDownNames[1][0].toString().toLowerCase() == "perspective"
                                        ? t("tournaments.perspective")
                                        : dropDownNames[1][0]}
                                  </label>
                                </div>
                                <div className="dobns_input_component">
                                  <input
                                    className="dobns_input "
                                    onClick={setDropDownHandler2}
                                    value={title2}
                                    placeholder={
                                      dropDownNames[1][0].toString().toLowerCase() == "type"
                                        ? t("contest.type")
                                        : dropDownNames[1][0].toString().toLowerCase() == "perspective"
                                          ? t("tournaments.perspective")
                                          : dropDownNames[1][0]
                                    }
                                  />
                                </div>
                              </div>

                              {showTitle2 ? (
                                <DropDownPop
                                  cancel={true}
                                  getTitle={getTitle2}
                                  showDropDownValues={showDropDownValues}
                                  values={dropDownNames[1][1].map((el) => el.value)}
                                />
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div className="flex gap" style={{ marginTop: '20px' }}>
                      <div className="form-group width">
                        <div style={{ marginBottom: -19, marginLeft: 15 }}>
                          <label className="scrim-input-label width ">{t("contest.number_of_entries")} </label>
                        </div>
                        <div className="dobns_input_component">
                          <input
                            type="number"
                            min="0"
                            onKeyDown={(e) => {
                              if (e.key === "-" || e.key === "." || e.key === "e") {
                                e.preventDefault();
                              }
                            }}
                            maxLength={3}
                            name="peas"
                            className="dobns_input"
                            onChange={(e) => {
                              // console.log("changed", e.target.value)
                              setNumberOfPlayer(e.target.value);
                            }}
                            value={numberOfPlayer ? numberOfPlayer : ""}
                            // defaultValue={scrimDetails?.noOfPlayerCapacity}
                            defaultValue={numberOfPlayer ? numberOfPlayer : ""}
                            placeholder={t("contest.enter_no_of_players")}
                          />
                        </div>
                      </div>

                      <div className="form-group width">
                        <div style={{ marginBottom: -19, marginLeft: 15 }}>
                          <label className="scrim-input-label width">{t("private_contest.number_of_winners")}</label>
                        </div>
                        <div className="dobns_input_component">
                          <input
                            type="number"
                            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                            min="0"
                            // onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                            // onKeyPress={}
                            maxLength={3}
                            name="peas"
                            className="dobns_input"
                            onChange={(e) => {
                              // console.log(e.target.value)
                              // console.log("changed")

                              setNumberOfWinner(e.target.value);
                            }}
                            value={numberOfWinner ? numberOfWinner : ""}
                            // defaultValue={scrimDetails?.noOfPlayerCapacity}
                            defaultValue={numberOfWinner ? numberOfWinner : ""}
                            placeholder={t("contest.enter_no_of_winner")}
                          />
                        </div>
                      </div>
                    </div>
                  </form>

                  <div></div>
                </main>
              )}
              {activeTab === "Game Rules" && (
                //  <GamesRules showError = {showError} payload={payload}  />
                <main className="scrim-basicDetails">
                  <TextEditor value={rulesData} setRulesData={ruleSet} readOnly={false} />

                  {/* text editor end */}
                  <div style={{ marginTop: "15%" }}>
                    {/* <span>*NOTE</span> : Rules above are Gamerji's default rules for this game. If you would like to add/remove something, please do
                    so and continue */}
                    {t("contest.notes_msg_create_contest")}
                  </div>
                </main>
              )}
              {/* <NextButton checkValidation={checkValidation}  scrimId={scrimId}/> */}
              {activeTab == "Basic Details" ? (
                <div className="bottom_btn" onClick={checkHandler}>
                  <button
                    // to={params?.id ? "/edit-scrim/" + params?.id : "/create-scrim-again"}
                    className="btn btn-dark main-btn">
                    {t("contest.next")}
                    <img src={rightArrow} alt="" className="mCS_img_loaded" />
                  </button>
                </div>
              ) : (
                <div className="bottom_btn" onClick={checkGameRules}>
                  <button
                    // to={params?.id ? "/edit-scrim/" + params?.id : "/create-scrim-again"}
                    className="btn btn-dark main-btn">
                    {t("contest.next")}
                    <img src={rightArrow} alt="" className="mCS_img_loaded" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrimCreate;
